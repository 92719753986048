/* Display */
.art_section {
    justify-content: center;
    width: 80%;
}

/* Images */
.artwork {
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 2rem;
}

.artwork img {
    width: 50vw;
    max-width: 20rem;
    height: auto;
    border: 4px solid #898277;
    border-radius: 10px;
    box-sizing: border-box;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.artwork img:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
}

/* Pop-up */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.popup-content {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 80vw;
    max-height: 80vh;
}

.popup-content img {
    width: 100%;
    height: auto;
    max-width: 80vw;
    max-height: 80vh;
    border-radius: 10px;
    object-fit: contain;
}
