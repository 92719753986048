.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 0;
}

.home-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

.home-title {
    text-align: center;
    line-height: 7rem;
    font-family: Merriweather, Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 5rem;
    font-weight: bold;
}