@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

/* Base styles */
html,
body {
  font-family: "Lato", "Calibri", sans-serif;
  margin: 0;
  padding: 0;
  color: var(--off_black);
  background-color: var(--off_white);
  overflow: hidden;
}

/* Inversion of color scheme */
.invert-theme {
  color: var(--off_white);
  background-color: var(--off_black);
}

/* Page sections */
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 0;
}

.page-body {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-bottom: auto;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.page-footer {
  text-align: center;
  opacity: .5;
}

/* Scrollable content wrapper */
.scroll-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Selection styling */
::-moz-selection {
  color: var(--off_black);
  background: var(--lighter_red);
}

::selection {
  color: var(--off_black);
  background: var(--lighter_red);
}

/* WebKit (Chrome, Safari, Edge) Scrollbar Styling */
.scroll-wrapper::-webkit-scrollbar {
  width: 12px;
}

.scroll-wrapper::-webkit-scrollbar-track {
  background: var(--off_black);
}

.scroll-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--light_red);
  border-radius: 6px;
  border: 4px solid var(--off_black);
}

.scroll-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: var(--lighter_red);
}

/* Scrollbar styling (home) */
.scroll-wrapper-home::-webkit-scrollbar-track {
  background: var(--off_white);
}

.scroll-wrapper-home::-webkit-scrollbar-thumb {
  background-color: var(--dark_red);
  border: 4px solid var(--off_white);
}

.scroll-wrapper-home::-webkit-scrollbar-thumb:hover {
  background-color: var(--light_red);
}

@-moz-document url-prefix() {
  .scroll-wrapper {
    scrollbar-color: var(--light_red) var(--off_black);
  }

  .scroll-wrapper-home {
    scrollbar-color: var(--dark_red) var(--off_white);
  }
}

/* Media Queries */
@media (max-width: 487px) {
  .page-footer {
    padding-bottom: 8rem;
  }
}