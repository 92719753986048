.line_break {
    margin: 2rem auto 1rem;
    width: 75%;
    height: 3px;
    background-color: var(--light_red);
    display: block;
}

.page-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 8rem;
}

.page-header h1 {
    font-family: Merriweather, Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 5rem;
    margin: 0;
    position: relative;
}

.content {
    font-size: 1rem;
    position: relative;
    /* text-align: center; */
    max-width: 80ch;
    margin: 0 1rem 0 1rem;
}

.content_subheader h2 {
    font-size: 2rem;
    padding: 0 0 .5rem 0;
}

.content_subtext {
    color: #a0a0a0;
}

.content p {
    padding-bottom: 1.5rem;
}

.content_about {
    line-height: 3rem;
}

.content_project {
    position: relative;
    /* margin-top: 5rem; */
    justify-content: flex-start;
    font-size: 1rem;
    text-align: center;
}

/* Media Queries */
@media (max-width: 487px) {
    .content, .content_project {
        padding-bottom: 4rem;
    }
    .line_break {
        margin-bottom: 3rem;
    }
}