a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    color: var(--dark_red);
    transition: color 0.5s ease;
}

nav {
    line-height: 0.5rem;
    margin: 1rem 0 2rem 0;
}

nav ul {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
    list-style: none;
}

nav ul li {
    font-size: 2rem;
    font-weight: bold;
    padding: 1.5vw;
}

#home-links a {
    padding: .5rem;
}

#back_arrow {
    display: block;
    position: absolute;
    margin-top: 2rem;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2rem;
    height: auto;
    color: var(--light_red);
    z-index: 10;
}

#back_arrow:hover {
    color: var(--lighter_red);
}

@media (max-width : 508px) {
    nav {
        padding: 20px 0;
        border-top: 1px solid #9c9c9c;
        border-bottom: 1px solid #9c9c9c;
        width: 80%;
    }

    nav ul li {
        font-size: 1.5rem;
        font-weight: normal;
    }
}

@media (max-height : 700px) {
    #back_arrow {
        margin-top: 2.5rem;
    }
}